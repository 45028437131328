$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.mainBlock').on('click', '[data-update-url]', function (e) {
        var url    = $(this).data('update-url');
        var method = "PUT";

        e.preventDefault();
    }).on('submit', 'form[data-confirm]', function (e) {
        return confirm($(this).data('confirm'));
    });

    $('.uk-alert:not(.alert-important)').delay(4000).slideUp(function () {
        $(this).remove();
    });

    $( document ).on( 'keyup', function( e ) {
        var modal = UIkit.modal( '.uk-modal' );
        if ( e.keyCode === 27 && modal.isActive() ) {
            modal.hide();
        }
    } );
})