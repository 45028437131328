'use strict';

;(function($, window, document, undefined)
{
    var isAdvancedUpload = function()
    {
        // return false;
        var div = document.createElement( 'div' );
        return ( ( 'draggable' in div ) || ( 'ondragstart' in div && 'ondrop' in div ) ) && 'FormData' in window && 'FileReader' in window;
    }();

    $('form.droppable').each(function () {
        var dropZone  = $(this),
            fileInput = dropZone.find('[type=file]'),
            submitBtn = dropZone.find('[type=submit]'),
            orDragLbl = dropZone.find('.orDrag');

        if ( isAdvancedUpload )
        {
            dropZone.addClass('enabled');
            submitBtn.hide();
            orDragLbl.removeClass('uk-hidden');

            dropZone.on('dragover dragenter', function() {
                dropZone.addClass('is-dragover');
            }).on('dragleave dragend drop', function() {
                dropZone.removeClass('is-dragover');
            }).on("dragover drop", function(e) {
                e.preventDefault();
            }).on("drop", function(e) {
                fileInput
                    .prop("files", e.originalEvent.dataTransfer.files)
            });

            fileInput.on('change', function () {
                if (fileInput.prop("files") && fileInput.prop("files").length > 0)
                    $(this).closest("form").submit();
            })
        }
    });

})( jQuery, window, document );